<template>
  <div class="w-full bg-gradient-to-r from-[#00446F] to-[#088BD5] p-0 relative">
    <div
      class="w-full md:w-5/6 min-h-page-login flex flex-col md:flex-row items-center md:justify-between m-auto"
    >
      <div class="w-full md:w-1/2 p-8 space-y-4 order-2 md:order-1">
        <p class="font-solusi text-white font-semibold text-4xl md:text-7xl">
          Ga Perlu Ribet Lagi!
        </p>
        <p class="font-solusi text-white font-normal text-lg">
          Rasakan kemudahan belajar hanya di aplikasi
          <span class="font-solusi text-white font-semibold text-lg"
            >SekolahDesain.id</span
          >
        </p>
        <p class="font-desain text-white font-semibold text-lg">Tersedia di</p>
        <div class="flex w-full space-x-6">
          <a
            href="https://play.google.com/store/apps/details?id=id.sekolahdesain.app"
            class=""
          >
            <img
              src="/assets/images/footer/play-store.png"
              class="m-auto w-40"
              alt=""
            />
          </a>
          <a href="https://apps.apple.com/us/app/sekolah-desain/id1592918511">
            <img
              src="/assets/images/footer/app-store.png"
              class="m-auto w-40"
              alt=""
            />
          </a>
        </div>
      </div>
      <div
        class="w-full md:w-1/2 mt-8 md:mt-0 md:p-6 flex justify-center md:justify-end order-1 md:order-2"
      >
        <img src="/assets/images/apps.png" class="w-3/4 md:w-5/6" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Download',
})
</script>
